import Header from '../header'
import Footer from '../footer'
import LanguageSwitch from '../LanguageSwitch'

export default function Layout ({ children, variant, classNameContent = '' }) {
  return (
    <>
      <Header variant={variant} />
      <main className={classNameContent}>{children}</main>
      <Footer />
      <LanguageSwitch />
    </>
  )
}
