import AsyncSelect from 'react-select/async'
import { useTranslation } from 'next-i18next'

export default function PluginsFilterSearch ({
  search,
  onSearchChange,
  placeholder,
  id = 'autocompleteSearch'
}) {
  const { t } = useTranslation(['common'])
  let timeout

  const getKeywords = async value => {
    const baseUrl = process.env.NEXT_PUBLIC_BACKEND_URL
    const response = await fetch(`${baseUrl}api/products/suggest?search=${value}`)

    return response.json()
  }

  /**
   * So we need two cases:
   * 1) Component suggests keywords, and we press enter (This only works with the onChange)
   * 2) Component does NOT suggest and we still want to continue (For that, we need this method)
   * There is of course a little race condition because when I press enter, the component will
   * call handleKeyDown and onChange, but that's fine, because the onChange will be called
   */
  const handleKeyDown = event => {
    if (event.keyCode !== 13) return

    onSearchChange(event.target.value)
  }

  const promiseOptions = value =>
    /**
     * AsyncSelect has multiple ways of returning async options
     * One of them is to return a promise
     * This promise or method does two things:
     * 1) It clears the timeout, so users don't get a new request for every keystroke
     * 2) It returns a promise that resolves to the keywords (Our autocomplete)
     */
    new Promise(resolve => {
      clearTimeout(timeout)

      timeout = setTimeout(async () => {
        const { data } = await getKeywords(value)
        resolve(data.autocomplete.map(keyword => ({ label: keyword })))
      }, 300)
    })

  return (
    <AsyncSelect
      id={id}
      instanceId={id}
      cacheOptions
      loadOptions={promiseOptions}
      defaultOptions
      classNamePrefix="app-select"
      noOptionsMessage={() => t('no_results', { ns: 'common' })}
      value={search}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          maxHeight: '60px',
          overflowY: 'none'
        })
      }}
      onKeyDown={handleKeyDown}
      onChange={keyword => onSearchChange(keyword.label)}
      placeholder={placeholder}
    />
  )
}
