import Link from 'next/link'
import HeaderPlugin from './header/HeaderPlugins'
import HeaderSearch from './header/HeaderSearch'
import Image from 'next/future/image'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIsAuthenticated, selectMe } from '../features/auth/authReducer'
import { useTranslation } from 'next-i18next'

export default function Header ({ variant }) {
  const { t } = useTranslation('header')
  const [isMoving, setIsMoving] = useState(false)
  const isAuthenticated = useSelector(state => selectIsAuthenticated(state.auth))
  const me = useSelector(state => selectMe(state.auth))

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setIsMoving(true)
      } else {
        setIsMoving(false)
      }
    })
  }, [])

  return (
    <header className={`${isMoving ? 'header--shadow' : ''} header--fixed bg-${variant}`}>
      <nav className={`navbar navbar-expand-xl bg-${variant}`}>
        <div className="container">
          <Link href="/">
            <a
              className="navbar-brand"
              href="#"
            >
              <Image
                src="/images/logo.svg"
                width={150}
                height={30}
                alt=""
              />
            </a>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#header"
            aria-controls="header"
            aria-expanded="false"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse row justify-content-between w-100 bg-${variant}`}
            id="header"
          >
            <div className="col-lg-auto col-12">
              <ul className="navbar-nav row align-items-md-center">
                <li className="nav-item dropdown col-auto">
                  <a
                    className="nav-link dropdown-toggle text-dark"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t('home.software')}
                  </a>
                  <HeaderPlugin />
                </li>
                <li className="nav-item col-auto">
                  <Link href="/plugins">
                    <a className="nav-link text-dark">{t('home.browse')}</a>
                  </Link>
                </li>
                <li className="nav-item col-auto">
                  <Link href="/services">
                    <a className="nav-link text-dark">{t('home.services')}</a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-12">
              <div className="row align-items-center justify-content-lg-end flex-lg-nowrap">
                <div className="col-12 col-md-4 col-lg-5 mb-3 mb-md-0">
                  <div>
                    <HeaderSearch />
                  </div>
                </div>
                <div className="col-12 col-md-auto">
                  <nav>
                    <ul className="navbar-nav align-items-md-center d-flex gap-4">
                      {!isAuthenticated && (
                        <>
                          <li className="nav-item">
                            <Link href="/become-a-seller">
                              <a className="btn btn-warning text-darkblue">{t('home.seller')}</a>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link href="/login">
                              <a className="btn">{t('home.login')}</a>
                            </Link>
                          </li>
                        </>
                      )}
                      {
                        (isAuthenticated && me) && (
                          <div className="col-lg-6 col-xl-12 col-12 d-flex justify-content-end">
                            <div className="dropdown">
                              <button
                                className="btn fw-normal border-0 dropdown-toggle d-flex align-items-center"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <Image
                                  alt=""
                                  src="/images/icons/profile.png"
                                  width={16}
                                  height={16}
                                  className="me-2"
                                />
                                {me?.full_name}
                              </button>
                              <ul className="dropdown-menu">
                                {
                                  me?.role === 'Seller' && (
                                    <li>
                                      <Link href="/seller/dashboard">
                                        <a className="dropdown-item">{t('seller.dashboard')}</a>
                                      </Link>
                                    </li>
                                  )
                                }
                                {
                                  me?.role === 'Admin' && (
                                    <li>
                                      <Link href="/admin/dashboard">
                                        <a className="dropdown-item">{t('seller.dashboard')}</a>
                                      </Link>
                                    </li>
                                  )
                                }
                                {
                                  me?.role === 'Distributor' && (
                                    <li>
                                      <Link href="/distributor/dashboard">
                                        <a className="dropdown-item">{t('seller.dashboard')}</a>
                                      </Link>
                                    </li>
                                  )
                                }
                                {
                                  me?.role === 'Buyer' && (
                                    <li>
                                      <Link href="/customer/dashboard">
                                        <a className="dropdown-item">{t('seller.dashboard')}</a>
                                      </Link>
                                    </li>
                                  )
                                }
                                <li>
                                  <Link href="/logout">
                                    <a className="dropdown-item">{t('seller.logout')}</a>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )
                      }
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
