import { useTranslation } from 'next-i18next'
import PluginsFilterSearch from '../plugins/filter/PluginsFilterSearch'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export default function HeaderSearch () {
  const { t } = useTranslation('header')
  const router = useRouter()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!search) return

    router.push(`/plugins?search=${search}`)
  }, [search])

  return (
    <PluginsFilterSearch
      placeholder={t('home.search')}
      id="headerSearch"
      search={search}
      onSearchChange={setSearch}
    />
  )
}
