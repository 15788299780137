import { useQuery } from '@apollo/client'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { GET_APPLICATIONS } from '../../services/graphql/queries/getApplications'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import useTranslatedRoutes from '../../services/translatedRoutes'

export default function HeaderPlugins () {
  const { t } = useTranslation('applications')
  const { data } = useQuery(GET_APPLICATIONS, {
    variables: {
      active_only: true
    }
  })

  const applications = useMemo(() => {
    if (!data) {
      return []
    }

    const rows = [...data.applications]

    return rows.sort((a, b) => a.name.localeCompare(b.name))
  })

  const router = useRouter()
  const language = router.locale.toUpperCase()
  const { getSoftwareUrl } = useTranslatedRoutes(language)

  return (
    <ul
      className="w-100 border-0 dropdown-menu dropdown-fullwidth rounded-lg-4 mt-lg-0 p-4 list-unstyled px-0 px-lg-3 px-xl-5 bg-light"
      aria-labelledby="navbarDropdown"
    >
      <div className="row row-cols-lg-4 row-cols-xl-5 w-100">
        {
          applications && applications.map((application, index) => (
            <li className="nav-item"
              key={index}
            >
              <Link href={getSoftwareUrl(application)}>
                <a className="nav-link fw-normal">{t(application.name)}</a>
              </Link>
            </li>
          ))
      }
      </div>
    </ul>
  )
}
